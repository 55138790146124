export type Results<A> = {
  total: number
  results: A[]
}

export type ResultsWithAggregations<A, B> = {
  total: number
  results: A[]
  aggregations?: B
}

export type PromiseResults<A> = Promise<Results<A>>
export type PromiseResultsWithAggregations<A, B> = Promise<ResultsWithAggregations<A, B>>

export const emptyResults = { total: 0, results: [] }

export type SearchParams = {
  query: string
  pageNumber: number
  pageSize: number
  sortBy?: string
  orderBy?: string
  facets?: string
  linkedANDSearchFieldsActive?: string[]
}

export type PublicSearchParams = {
  mi?: number
  ps?: string
  pc?: string
  sc?: string
  et?: string
  mrlistId?: string
  audi?: number
  page: number
  size: number
}

export enum PublicationFormat {
  PLAIN = 'plain',
  AMS = 'ams',
  BIB = 'bib',
  TEX = 'tex',
  END = 'end',
}

export type PublicationFormatResult = {
  paperId: number
  ams: string
  bib: string
  tex: string
  plain: string
  end: string
}

export type SerialMinimal = {
  title: string
  titleAbbreviation: string
}

export type UserList = {
  id?: string
  label: string
  mrNumbers: number[]
}
