
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Card extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly htmlTitle!: string
  @Prop({ default: false, type: Boolean }) showOnSmallScreenOnly!: boolean
  @Prop({ default: false, type: Boolean }) lightHeaderStyle!: boolean
  @Prop({ default: false, type: Boolean }) hideHeader!: boolean
  @Prop({ default: false, type: Boolean }) noShadow!: boolean
  @Prop({ default: false, type: Boolean }) noBorder!: boolean

  get headerClass() {
    const cond = this.showOnSmallScreenOnly ? 'd-md-none' : ''
    const hiddenHeader = this.hideHeader ? 'd-none' : ''
    // return `border-bottom highlight-border p-2 ${hiddenHeader}`
    return `border-bottom highlight-border p-2 ${cond} ${hiddenHeader}`
  }

  get footerClass() {
    const cond = this.showOnSmallScreenOnly ? 'd-md-none' : ''
    return `p-0 position-relative ${cond}`
    // return 'p-0 position-relative'
  }

  get cardClass() {
    const cond = this.showOnSmallScreenOnly ? 'shadow-show shadow-border-md-none' : ''

    const shadow = !this.noShadow && !this.showOnSmallScreenOnly ? 'shadow' : ''
    const border = this.noBorder ? 'border-0' : ''
    return `${shadow} ${border} mb-4 ${cond}`
    // return `${shadow} ${border} mb-4`
  }

  get titleClass() {
    // return this.lightHeaderStyle
    //   ? 'm-0 non-selectable'
    //   : 'm-0 contrast-boost non-selectable'
    return 'm-0 non-selectable'
  }

  get headerBGVariant() {
    return this.lightHeaderStyle ? 'white' : 'ams-gray'
  }

  get headerTextVariant() {
    return this.lightHeaderStyle ? 'gray' : 'black'
  }
}

