
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class InternalLinks extends Vue {
  @Prop() mrnumber!: number
  @Prop({ default: false }) internalAccess!: boolean

  showHistory = false

  scaleIFrame() {
    const iframe = this.$refs.iframe as HTMLIFrameElement
    if (!iframe || !iframe.contentWindow || !iframe.contentWindow.document || !iframe.contentWindow.document.body || iframe.contentWindow.document.body.scrollHeight <= 150) {
      setTimeout(() => {
        this.scaleIFrame()
      }, 500)
      return
    }
    iframe.height = iframe.contentWindow?.document.body.scrollHeight + 'px'
  }
}
